import React, { Fragment, useCallback, useEffect, useContext } from 'react';
import type { ComponentProps, FC } from 'react';
import { useInView } from 'react-intersection-observer';

import type { RendererAppearance } from '@atlaskit/renderer';
import FeatureGates from '@atlaskit/feature-gate-js-client';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';

import { GenericPremiumTipLoadable } from '@confluence/premium-trial/entry-points/GenericPremiumTipLoadable';
import {
	PageCommentsCountProvider,
	ReactionsProvider,
	NewCommentsProvider,
} from '@confluence/comment-context';
import { ArchivedPageBanner } from '@confluence/archive-pages';
import { ContentViewedEvent, ContentRefetchProvider } from '@confluence/content-body';
import {
	CUSTOM_HEADER_FOOTER_TYPES,
	CustomHeaderFooterLoader,
} from '@confluence/custom-header-footer';
import { fg } from '@confluence/feature-gating';
import { LocalActivityViewPageTracker } from '@confluence/local-activity';
import {
	getRendererCoverPictureInfo,
	RendererPageCoverPicture,
} from '@confluence/page-cover-picture';
import { QuickReload, PageMode } from '@confluence/quick-reload';
import { ScrollToPageStart } from '@confluence/scroll';
import {
	LivePageComponentLoader as LivePageComponentClient,
	LivePageComponentServer,
	LivePagesChangeboardingManagerLoader,
} from '@confluence/live-pages';
import { ConvertToLivePageFlag } from '@confluence/live-pages-features/entry-points/ConvertToLivePageFlag';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { LivePagesInProductPromptModal } from '@confluence/live-pages-features/entry-points/LivePagesInProductPromptModal';
import { LIVE_PAGE_SUBTYPE } from '@confluence/live-pages-utils';
import { useSessionData, useBooleanFeatureFlag } from '@confluence/session-data';
import { isOnboardingExpEnabled } from '@confluence/growth-experiment-helpers';
import { EndOfPageRecommendation } from '@confluence/page-recommendations';
import { InlineCommentsHighlighterLoader } from '@confluence/inline-comments/entry-points/InlineCommentsHighlighterLoader';
import { ViewPageLabelsLoader } from '@confluence/labels';
import {
	getAutoConvertionInfo,
	AutoConversionByLineContextProvider,
} from '@confluence/editor-conversion';
import { LoadingPriority } from '@confluence/loadable';
import { ContentOwnershipContextProvider } from '@confluence/content-ownership';
import { useIsLivePage } from '@confluence/live-pages-utils/entry-points/useIsLivePage';
import { useLivePageStoreActions } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { ReadingAidsAcronymsHighlighter } from '@confluence/contextual-reading-aids/entry-points/readingAidsAcronymsHighlighter';
import { RENDERER_ANNOTATION_PROVIDER_SSR_FF } from '@confluence/annotation-provider/entry-points/featureFlags';
import { CommentsPillOverlay } from '@confluence/page-comments';
import { RendererActionsProviderIfEnabled } from '@confluence/renderer-actions';
import {
	DocumentUpdateStatusProvider,
	AnnotationsProviderIfEnabled,
} from '@confluence/annotation-provider-store';
import { getSSRFeatureFlag } from '@confluence/ssr-utilities';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { SPAViewContext } from '@confluence/spa-view-context';
import {
	usePremiumTipState,
	useEligibilityBiteSizedPremiumMessages,
} from '@confluence/premium-trial/entry-points/BiteSizeOnboarding';
import { AutomationPremiumTipLoader } from '@confluence/premium-trial/entry-points/AutomationPremiumTipLoader';

import type { BaseViewPageProps } from './BaseViewPageProps';
import { ContentTitleWithByLine } from './ContentTitleWithByLine';
import { SatisfactionSurveys } from './SatisfactionSurveys';
import { ViewPageFooterComments } from './ViewPageFooterComments';
import { ViewPageInlineComments } from './ViewPageInlineComments';
import { ViewPageContainer } from './ViewPageContainer';
import { ViewPageContent } from './ViewPageContent';
import { ViewPageReactions } from './ViewPageReactions';
import { RelatedTopics } from './RelatedTopics';
import { useRenderLivePageEarly } from './useRenderLivePageEarly';
import { ReactionsPlacement, ViewPageReactionsExperiment } from './ViewPageReactionsExperiment';

const LivePageComponent = process.env.REACT_SSR ? LivePageComponentServer : LivePageComponentClient;

const setLoadingPriority = (hasAllowedFeature: boolean) => {
	if (isEmbeddedConfluence_DO_NOT_USE()) {
		return hasAllowedFeature ? LoadingPriority.AFTER_PAINT : LoadingPriority.LAZY;
	}
	return null;
};

type ContentTitleAndByLineOptions = Pick<
	ComponentProps<typeof ContentTitleWithByLine>,
	'hasByLineContributors' | 'hasByLineExtensions' | 'headingContainer'
>;

type Props = BaseViewPageProps & {
	contentHeader?: React.ReactNode;
	hasPageComments?: boolean;
	hasSatisfactionSurveys?: boolean;
	hasHighlightActions?: boolean;
	hasInlineComments?: boolean;
	hasLabels?: boolean;
	hasReactions?: boolean;
	hasEOPRecs?: boolean;
	hasQuickReload?: boolean;
	appearance?: string;
	classicComments?: React.ComponentType<any>;
	hasInlineActions?: boolean;
	contentScreenStyles?: ComponentProps<typeof ViewPageContainer>['contentScreenStyles'];
	eventHandlers?: React.ComponentProps<typeof ViewPageContent>['eventHandlerOverrides'];
	titleAndByLineOptions?: ContentTitleAndByLineOptions;
	children?: React.ReactNode;
	setHasCoverPicture?: (hasCoverPicture: boolean) => void;
	isEmbeddedPage?: boolean;
	ClassicEditorContextProviderLoader?: React.ComponentType<any>;
	renderLivePageAsViewPage?: boolean;
	cachedPageData?: { subType?: string; isArchived?: boolean };
};

// NOTE: `contentId` represents the user's intended page but not always the displayed one. During transition to a new page, the component still renders the previous page until ViewPageContainer updates the contentQueryData.
//       While fetching new contentQueryData, the prior contentQueryData is displayed, causing a brief mismatch between contentId and the contentQueryData's contentId.
export const ViewPageCommon: FC<Props> = ({
	spaceKey,
	contentId,
	contentHeader,
	contentScreenStyles,
	classicComments,
	eventHandlers,
	titleAndByLineOptions,
	hasPageComments = true,
	hasSatisfactionSurveys = true,
	hasHighlightActions = true,
	hasInlineComments = true,
	hasInlineActions = true,
	hasLabels = true,
	hasReactions = true,
	hasEOPRecs = true,
	hasQuickReload = true,
	appearance,
	children,
	setHasCoverPicture,
	isEmbeddedPage,
	ClassicEditorContextProviderLoader,
	renderLivePageAsViewPage = false,
	cachedPageData = {},
}) => {
	const { featureFlagClient, isAdminHubAIEnabled, locale, isRovoEnabled } = useSessionData();
	const isLivePagesEnabled = useIsLivePagesFeatureEnabled();
	const isEditorExpEnabled = isOnboardingExpEnabled(featureFlagClient);
	const isCustomSitesPageTitleFFOn = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.page-header-and-title',
	);
	const isReadingAidsAutoHighlightEnabled =
		(isAdminHubAIEnabled || isRovoEnabled) &&
		locale.startsWith('en') &&
		FeatureGates.getExperimentValue('cc_reading_aids_auto-highlight_acronyms', 'isEnabled', false);

	const isSSRRendererAnnotationProviderEnabled = getSSRFeatureFlag(
		RENDERER_ANNOTATION_PROVIDER_SSR_FF,
	);
	const isLivePagesInProductPromptEnabled = useBooleanFeatureFlag(
		'confluence.frontend.live-pages-in-product-prompt',
	);
	const { isSiteAdmin } = useContext(SPAViewContext);

	// Setup for A/A experiment for testing stratified sampling with Statsig
	FeatureGates.getExperimentValue('cc_stratified_sampling_exp_aa', 'cohort', 'not-controlled', {
		fireExperimentExposure: true,
	});

	let stickyHeader = contentHeader;

	const isLivePage = useIsLivePage();
	const { setIsLive } = useLivePageStoreActions();

	const [pageCommentsSectionRef, pageCommentsSectionInView] = useInView();
	const [topReactionsRef, topReactionsSectionInView] = useInView();
	const [bottomReactionsRef, bottomReactionsSectionInView] = useInView();

	const canShowRediscoverReactionsExperiment = hasReactions && !isLivePage && !isEmbeddedPage;

	const { overrideUFORouteName } = useRouteActions();

	const setLivePageGlobalState = useCallback(
		(isContentLive: boolean) => {
			void setIsLive(isContentLive);

			if (isContentLive) {
				overrideUFORouteName('live-edit');
			}
		},
		[setIsLive, overrideUFORouteName],
	);

	useEffect(() => {
		if (!isLivePage) return; // no cleanup needed

		// This is the cleanup function that runs when the component unmounts
		return () => {
			setLivePageGlobalState(false);
		};
	}, [isLivePage, setLivePageGlobalState]);

	// stickyHeader needs to be removed based on live page state since it's used in ViewPageContainer props
	if (isLivePage) {
		stickyHeader = undefined;
	}
	const { renderLivePageEarly, isLivePageCacheOutdated } = useRenderLivePageEarly({
		contentId,
		cachedSubType: cachedPageData.subType,
		cachedIsArchived: cachedPageData.isArchived,
		renderLivePageAsViewPage,
	});

	useEffect(() => {
		if (renderLivePageEarly) {
			setLivePageGlobalState(true);
		}
	}, [renderLivePageEarly, setLivePageGlobalState]);

	const { showPrompt, source } = usePremiumTipState();
	const { isFeatureFlagEnabled: isPremiumTipPromptEligible } =
		useEligibilityBiteSizedPremiumMessages();
	const displayGenericPremiumTip =
		isPremiumTipPromptEligible && !process.env.REACT_SSR && !showPrompt;

	return (
		<Fragment>
			<ContentOwnershipContextProvider>
				<NewCommentsProvider>
					<PageCommentsCountProvider contentId={contentId}>
						<DocumentUpdateStatusProvider>
							<ContentRefetchProvider>
								<RendererActionsProviderIfEnabled>
									<AnnotationsProviderIfEnabled>
										{renderLivePageEarly ? (
											<LivePageComponent
												contentId={contentId}
												spaceKey={spaceKey}
												ClassicEditorContextProviderLoader={ClassicEditorContextProviderLoader}
											/>
										) : (
											<CommentsPillOverlay contentId={contentId} inView={pageCommentsSectionInView}>
												<ViewPageContainer
													spaceKey={spaceKey}
													contentId={contentId}
													stickyHeader={stickyHeader}
													contentScreenStyles={contentScreenStyles}
												>
													{({ isThemed, contentQueryData, contentQueryError }) => {
														const contentStatus = contentQueryData?.content?.nodes?.[0]?.status;
														const contentType = contentQueryData?.content?.nodes?.[0]?.type;
														const contentSubType = contentQueryData?.content?.nodes?.[0]?.subType;
														const spaceId = contentQueryData?.content?.nodes?.[0]?.space?.id;
														const contentIdFromQuery = contentQueryData?.content?.nodes?.[0]?.id;
														const lastModifiedDate =
															contentQueryData?.content?.nodes?.[0]?.metadata?.lastModifiedDate;
														const pageWidthType =
															contentQueryData?.content?.nodes?.[0]?.appearancePublished?.nodes?.[0]
																?.value || `"default"`;
														const isFabricPage = Boolean(
															contentQueryData?.content?.nodes?.[0]?.body?.dynamic
																?.representation === 'atlas_doc_format',
														);

														// getting info cover picture needs
														const {
															coverPicture,
															contentAppearance,
															hasCoverPicture,
															coverPictureWidth,
														} = getRendererCoverPictureInfo(contentQueryData);
														// getting info for auto conversion
														const { queryDataId, editorProperty, fabricEditorSupported } =
															getAutoConvertionInfo(contentQueryData?.content?.nodes?.[0]);

														// If live page is archived, we don't show LivePageComponent. Instead, archived live pages will render using the existing ViewPageContentRenderer.
														const isContentLive =
															contentQueryData?.content?.nodes?.[0]?.subType ===
																LIVE_PAGE_SUBTYPE && contentStatus !== 'archived';

														if (isLivePagesEnabled) {
															setLivePageGlobalState(isContentLive);

															if (
																isContentLive &&
																!contentQueryError &&
																!!contentIdFromQuery &&
																!renderLivePageAsViewPage
															) {
																return (
																	<LivePageComponent
																		contentId={contentIdFromQuery}
																		spaceKey={spaceKey}
																		ClassicEditorContextProviderLoader={
																			ClassicEditorContextProviderLoader
																		}
																	/>
																);
															}
														}
														const renderContentTitle = () => (
															<ContentTitleWithByLine
																banner={<ArchivedPageBanner contentId={contentId} />}
																contentId={contentId}
																spaceKey={spaceKey || ''}
																hasCoverPicture={hasCoverPicture}
																pageWidthType={pageWidthType}
																isEmbeddedPage={isEmbeddedPage}
																{...titleAndByLineOptions}
															>
																{canShowRediscoverReactionsExperiment && (
																	<div ref={topReactionsRef}>
																		<ReactionsProvider contentId={contentId}>
																			<ViewPageReactionsExperiment
																				contentId={contentId}
																				contentType={contentType}
																				spaceId={spaceId}
																				contentSubType={contentSubType}
																				reactionsPlacement={ReactionsPlacement.BYLINE}
																			/>
																		</ReactionsProvider>
																	</div>
																)}
															</ContentTitleWithByLine>
														);

														if (isCustomSitesPageTitleFFOn && !!setHasCoverPicture) {
															setHasCoverPicture(hasCoverPicture);
														}

														return (
															<Fragment>
																<ScrollToPageStart
																	key={`ScrollToPageStart-${contentId}`}
																	isSmooth={false}
																/>
																{isThemed && contentHeader}
																<CustomHeaderFooterLoader
																	type={CUSTOM_HEADER_FOOTER_TYPES.HEADER}
																/>
																{hasCoverPicture && (
																	<RendererPageCoverPicture
																		contentId={contentId}
																		isPagePreview={false}
																		coverPicture={coverPicture}
																		contentAppearance={contentAppearance}
																		isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
																		isSpaceOverview={false}
																		coverPictureWidthOverride={coverPictureWidth}
																	/>
																)}
																<AutoConversionByLineContextProvider
																	editorProperty={editorProperty}
																	fabricEditorSupported={fabricEditorSupported}
																	queryDataId={queryDataId}
																>
																	{renderContentTitle()}
																</AutoConversionByLineContextProvider>
																{/* Body of content */}
																{showPrompt && isPremiumTipPromptEligible && (
																	<AutomationPremiumTipLoader source={source} />
																)}
																<ViewPageContent
																	hasInlineComments={hasInlineComments}
																	contentId={contentId}
																	data={contentQueryData}
																	error={contentQueryError}
																	spaceKey={spaceKey}
																	hasHighlightActions={hasHighlightActions}
																	eventHandlerOverrides={eventHandlers}
																	appearance={appearance as RendererAppearance}
																	hasInlineActions={hasInlineActions}
																	lastModifiedDate={lastModifiedDate || undefined}
																	isEmbeddedPage={isEmbeddedPage}
																	isLivePageCacheOutdated={isLivePageCacheOutdated}
																/>
																{hasInlineComments && (
																	<InlineCommentsHighlighterLoader
																		pageId={contentId}
																		isFabricPage={isFabricPage}
																	/>
																)}
																{isReadingAidsAutoHighlightEnabled && (
																	<ReadingAidsAcronymsHighlighter contentId={contentId} />
																)}

																{hasLabels && (
																	<ViewPageLabelsLoader
																		pageWidthType={pageWidthType}
																		contentId={contentId}
																		contentType={contentType}
																		spaceKey={spaceKey}
																		spaceId={spaceId}
																		contentSubType={contentSubType}
																	/>
																)}
																{/* Render all secondary content - likes, labels, comments, etc. */}
																{(contentType == 'page' || contentType == 'blogpost') &&
																	fg('ai_topics_surface_confluence_page') && (
																		<RelatedTopics
																			pageWidthType={pageWidthType}
																			entityId={contentId}
																			contentType={contentType}
																		/>
																	)}

																{hasEOPRecs &&
																	(contentType == 'page' || contentType == 'blogpost') && (
																		<EndOfPageRecommendation
																			entityType={contentType || ''}
																			entityId={contentId}
																			spaceKey={spaceKey || ''}
																			pageWidthType={pageWidthType}
																		/>
																	)}

																{!process.env.REACT_SSR && (
																	<ContentViewedEvent contentId={contentId} />
																)}
																{hasReactions &&
																	/* Disable unsupported reactions when rendering live page as view page */ !isLivePage && (
																		<ReactionsProvider contentId={contentId}>
																			<div ref={bottomReactionsRef}>
																				<ViewPageReactions
																					hasReactions={hasReactions}
																					contentId={contentId}
																					contentType={contentType}
																					spaceId={spaceId}
																					contentSubType={contentSubType}
																				/>
																			</div>
																			{canShowRediscoverReactionsExperiment && (
																				<ViewPageReactionsExperiment
																					contentId={contentId}
																					contentType={contentType}
																					spaceId={spaceId}
																					contentSubType={contentSubType}
																					isHidden={
																						topReactionsSectionInView ||
																						bottomReactionsSectionInView
																					}
																					reactionsPlacement={ReactionsPlacement.FLOATING}
																				/>
																			)}
																		</ReactionsProvider>
																	)}
																{displayGenericPremiumTip && (
																	<GenericPremiumTipLoadable
																		isCommentSectionInView={pageCommentsSectionInView}
																	/>
																)}
																{hasPageComments &&
																	/* Disable unsupported page comments when rendering live page as view page */ !isLivePage && (
																		<div ref={pageCommentsSectionRef}>
																			<ViewPageFooterComments
																				key={contentId}
																				contentId={contentId}
																				loadingPriority={setLoadingPriority(hasPageComments)}
																				classicComments={classicComments}
																				hasReactions={hasReactions}
																			/>
																		</div>
																	)}

																<CustomHeaderFooterLoader
																	type={CUSTOM_HEADER_FOOTER_TYPES.FOOTER}
																/>
																{hasQuickReload && (
																	<QuickReload
																		contentId={contentId}
																		contentType={contentType}
																		// The 'Date.now()' is expedient; see notes in quick-reload
																		// package on what a more correct lastPollTime value would be.
																		lastPollTime={Date.now()}
																		pageMode={PageMode.RENDERER}
																		pageCommentsSectionInView={pageCommentsSectionInView}
																		isFabricPage={isFabricPage}
																	/>
																)}
																{hasSatisfactionSurveys && (
																	<SatisfactionSurveys
																		contentId={contentId}
																		isEditorExpEnabled={isEditorExpEnabled}
																	/>
																)}
																{contentType === 'page' && (
																	<>
																		<LocalActivityViewPageTracker contentId={contentId} />
																		{isLivePagesEnabled && (
																			<LivePagesChangeboardingManagerLoader origin="view-page" />
																		)}
																	</>
																)}
																{!isLivePagesEnabled &&
																	isLivePagesInProductPromptEnabled &&
																	isSiteAdmin && <LivePagesInProductPromptModal />}

																{children}
																{hasInlineComments && (
																	<ViewPageInlineComments
																		key={contentId}
																		loadingPriority={setLoadingPriority(hasInlineComments)}
																		contentId={contentId}
																		isFabricPage={isFabricPage}
																		isSSRRendererAnnotationProviderEnabled={
																			isSSRRendererAnnotationProviderEnabled
																		}
																	/>
																)}
																{isLivePagesEnabled && contentType === 'page' && (
																	<ConvertToLivePageFlag />
																)}
															</Fragment>
														);
													}}
												</ViewPageContainer>
											</CommentsPillOverlay>
										)}
									</AnnotationsProviderIfEnabled>
								</RendererActionsProviderIfEnabled>
							</ContentRefetchProvider>
						</DocumentUpdateStatusProvider>
					</PageCommentsCountProvider>
				</NewCommentsProvider>
			</ContentOwnershipContextProvider>
		</Fragment>
	);
};
